<template>
  <a
    id="mlh-trust-badge"
    class="banner"
    href="https://mlh.io/na?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2025-season&utm_content=white"
    target="_blank"
    ><img
      src="https://s3.amazonaws.com/logged-assets/trust-badge/2025/mlh-trust-badge-2025-white.svg"
      alt="Major League Hacking 2025 Hackathon Season"
      style="width: 100%"
  /></a>
</template>
<style scoped>
.banner {
  display: block;
  max-width: 100px;
  min-width: 60px;
  position: fixed;
  right: 50px;
  top: 0;
  width: 10%;
  z-index: 10000;
}
@media (max-width: 700px) {
  .banner {
    right: 5px;
  }
}
</style>